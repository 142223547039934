import {Component, Input, Type} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent {

  @Input('alertTarget') alertTarget!:string;
  message:string = '';
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
    switch(this.alertTarget) {
      case 'file':
        this.message = 'questo file';
        break;
      case 'image':
        this.message = 'questa immagine';
        break;
      case 'shop':
        this.message = 'questa attività';
        break;
    }
  }

}
