import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { FileDropComponent } from './components/file-drop/file-drop.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MultiImgModalComponent } from './components/multi-img-modal/multi-img-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { LoadingComponent } from './components/loading/loading.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SideMenuComponent,
    PhoneMaskDirective,
    FileDropComponent,
    MultiImgModalComponent,
    ConfirmModalComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgxFileDropModule
  ],
  exports:[
    HeaderComponent,
    FooterComponent,
    SideMenuComponent,
    PhoneMaskDirective,
    FileDropComponent,
    MultiImgModalComponent,
    ConfirmModalComponent,
    LoadingComponent
  ]
})
export class CommonElemModule { }
