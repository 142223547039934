<div class="p-md-5 mt-3">
    <div class="w-100 m-auto">
        <ngx-file-drop class="" dropZoneLabel="Trascina qui i tuoi file, oppure" (onFileDrop)="dropped($event)" 
        (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
            <ng-template class="p-2 h-100" ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">  
                <span class="me-2">Trascina qui i tuoi file, oppure</span>
                <button type="button" class="btn btn-light" (click)="openFileSelector()">Apri cartella</button>
            </ng-template>
        </ngx-file-drop>

        <!--div class="upload-table">
            <table class="table">
                <thead>
                    <tr>
                        <th>File Inseriti</th>
                    </tr>
                </thead>
                <tbody class="upload-name-style">
                    <tr *ngFor="let item of files; let i=index">
                        <td><strong>{{ item.relativePath }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div-->
    </div>
</div>