import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, tap } from 'rxjs/operators';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public stateService: StateService,) { }

	staticAlertClosed = false;
	message : {text:string, type:string} | undefined;
 
	@ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;

	ngOnInit(): void {
		this.stateService.message$.pipe(
      //debounceTime(500),
      tap(()=>{
          setTimeout(() => { 
            if (this.selfClosingAlert) {
              this.selfClosingAlert.close();
            }
          },2500)
        }
      )
    ).subscribe((message) => {
      this.message = message;
    });
  }
}
