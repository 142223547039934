<div class="w-100 h-100 d-flex flex-column">
    <div class="d-grid gap-2 mt-4 mx-2">
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='shops'><i class="bi bi-shop-window"></i><span class="ms-2 d-none d-md-inline">Attività</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='coupons'><i class="bi bi-ticket-perforated"></i><span class="ms-2 d-none d-md-inline">Coupon</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='prizes'><i class="bi bi-trophy"></i><span class="ms-2 d-none d-md-inline">Premi</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='ads'><i class="bi bi-badge-ad"></i><span class="ms-2 d-none d-md-inline">Visibilità</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='statement'><i class="bi bi-arrow-down-up icon-rotate"></i><span class="ms-2 d-none d-md-inline">Movimenti</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="closeSideMenu()" routerLink='account'><i class="bi bi-person-circle"></i><span class="ms-2 d-none d-md-inline">Account</span></button>
        <button class="btn btn-light fs-inherit" type="button" (click)="logout()"><i class="bi bi-box-arrow-right"></i><span class="ms-2 d-none d-md-inline">Logout</span></button>
    </div>
</div>