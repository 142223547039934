import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StoreFilesService {
  private storeImgsApiUrl = environment.FILES;

  constructor(private http: HttpClient) {}

  saveFilesToPath(files: any[],path: string = ''): Observable<string[]> {
    if (files.length) {
        const formData = new FormData();
      
        // Append files to the virtual form.
        for (const file of files) {
          formData.append(file.name, file)
        }
      
        // Optional, append other kev:val rest data to the form.
        /*Object.keys(restObj).forEach(key => {
          formData.append(key, restObj[key]);
        });*/
        formData.append('path', path);
        
        // Send it.
      return this.http.post<string[]>(`${this.storeImgsApiUrl}save`, formData);
    } else {
      return new Observable<string[]>((observer) => {
        observer.next([]);
      });
    }
  }
}


/*   sudo chcon -t httpd_sys_rw_content_t |FOLDER| -R   */ 